<template>
    <v-navigation-drawer
        :mini-variant.sync="mini"
        permanent app clipped
        width="200"
    >
        <v-list dense>
            <v-list-item link :to="link.to" v-for="link in menu" :key="JSON.stringify(link)">
                <v-list-item-icon>
                    <v-icon>{{link.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{link.text}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import menu from "./menu";

    export default {
        name: "AdminDrawerMenu",
        props: ['mini'],
        data () {
            return {
                menu: menu,
            }
        },
    }
</script>

<style scoped>

</style>
