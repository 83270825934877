export default [
    // {
    //     to: {name: 'admin.index'},
    //     icon: 'mdi-home',
    //     text: 'Dashboard',
    // },
    {
        to: {name: 'admin.price-plans.index'},
        icon: 'mdi-cash-multiple',
        text: 'Price Plans',
    },
    {
        to: {name: 'admin.users.index'},
        icon: 'mdi-account',
        text: 'Users',
    },
    // {
    //     to: {name: 'admin.surveys.index'},
    //     icon: 'mdi-file-document',
    //     text: 'Surveys',
    // },
]
